.boxForm {
    .MuiInputAdornment-root {
        color: #2290E0;
        margin-left: -2px;
    }
}
.newСonference {
    margin-bottom: 12px;
    .maxWidth {
        max-width: 200px;
    }
}
.sortBox {
    &__autocomplete {
        max-width: 420px;
        width: 100%;
        .MuiInputBase-root {
            border-radius: 2px;
        }
    }
    .sortBoxSelect {
        display: flex;
        align-items: center;
    }
    &__title {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        margin-right: 40px;
    }
    &__sortName {
        display: flex;
        align-items: center;
        .MuiSelect-select.MuiSelect-select {
            color: #2290E0;
            font-size: 14px;
        }
    }
    &__sortLabel {
        margin-right: 10px;
        color: #333;
        font-weight: 600;
    }
}
.sortBoxRight {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}
.boxTime {
    margin-top: -8px;
    &__title {
        font-size: 12px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.54);
    }
    &__item {
        display: flex;
        align-items: flex-end;
        padding-top: 4px;
    }
    input[type="time"]::-webkit-clear-button {
        display: none;
    }
    .boxForm {
        &:first-child {
            &:after {
                content: '-';
                position: absolute;
                font-weight: 700;
                right: 6px;
                top: 4px;
                z-index: -1;
            }
        }
    }
}
.MuiFormControl-root.repeatWidth {
    min-width: 154px;
}
.emptyUsers {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #757575;
}
.newConference {
    .emptyUsers {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
}