.countDown {
    background: linear-gradient(231.83deg, #03BEB5 0.44%, #08A7FF 59.97%, #678CFF 101.93%);
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
        max-width: 473px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        color: #fff;
        span {
            display: block;
        }
    }
    .title {
        font-weight: 700;
        margin-bottom: 46px;
    }
    button.MuiButton-outlinedPrimary {
        border-color: #fff;
        border-width: 2px;
        color: #fff;
    }
    button.MuiButton-containedPrimary {
        background-color: #fff;
        color: #2290E0;
    }
    .countTimer {
        border-radius: 10px;
        background-color: #fff;
        min-height: 138px;
        box-sizing: border-box;
        padding: 10px 40px;
    }
    .modalButtonGroup {
        margin-top: 100px;
    }
    .timerTitle {
        font-size: 12px;
        color: #757575;
        margin-bottom: 10px;
    }
    .currentGoes {
        font-size: 20px;
        font-weight: 700;
        margin-top: 16px;
    }
    .countdown {
        display: flex;
        justify-content: space-between;
        color: #757575;
        .number {
            font-size: 48px;
            color: #000000;
        }
        .text {
            font-size: 12px;
        }
        .colon {
            font-size: 28px;
            line-height: 68px;
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}