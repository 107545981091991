.participantsBox {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 10px 0 8px;
    min-width: 306px;
    max-width: 306px;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    min-height: 136px;
    text-decoration: none;
    cursor: pointer;
    display: block;
    color: #333333;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
    &:hover {
        .participantsBox__title {
            background: #DEEEFA;
            border-color: #258FE5;
            &::after {
                border-color: transparent;
            }
        }
    }
    &__title {
        font-weight: 600;
        margin-bottom: 12px;
        font-size: 14px;
        min-height: 46px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        transition: all .3s ease;
        border-left: 2px solid transparent;
        position: relative;
        &::after {
            content: '';
            border: 0.5px solid #DADADA;
            position: absolute;
            width: calc(100% - 16px);
            left: 16px;
            bottom: 0;
            right: 0;
            transition: all .3s ease;
        }
    }
    &__bottom {
        margin-top: 14px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .bottomItem {
        display: flex;
        align-items: center;
        &:first-child {
            margin-bottom: 12px;
        }
        .icon {
            height: 13px;
            margin-right: 10px;
        }
    }
}
.btnRow {
    .MuiButton-outlined {
        margin-right: 20px;
    }
}
.cityTitle {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 18px;
}
.mb-1 {
    margin-bottom: 4px;
}