@font-face {
  font-family: "AristaProTrial"; 
  src: url("../../fonts/ARISTA-PRO-REGULAR-TRIAL_0.TTF") format("truetype"); 
  font-style: normal; 
  font-weight: normal; 
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #201E3E;
  margin: 0;
  padding-top: 56px;
  background-color: #F8FAFC;
}

header.MuiPaper-elevation4 {
  box-shadow: none;
  background: linear-gradient(187.03deg, #04A9A1 0.4%, #08A7FF 38.28%, #4376C8 97.12%);
}
.MuiList-root.MuiMenu-list .MuiButtonBase-root.MuiListItem-root {
  font-size: 14px;
}
.mainContainer {
  margin-left: 200px;
}
h2.title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  line-height: 1.4;
}
.content {
  box-sizing: border-box;
  position: relative;
}
.whiteBg {
  padding: 37px 24px 28px;
  background-color: #fff;
}
.grayBg {
  padding: 24px;
}
input.MuiInputBase-input {
  caret-color: #2290E0;
}
button.MuiButton-containedPrimary {
  border-radius: 2px;
  height: 36px;
  font-size: 14px;
  padding-left: 26px;
  padding-right: 26px;
  text-transform: none;
  text-decoration: none;
  background: #2290E0;
  color: #fff;
  &:hover {
    background-color: #375A9C;
  }
}
button.MuiButton-outlinedPrimary {
  border-radius: 2px;
  height: 36px;
  font-size: 14px;
  padding-left: 26px;
  padding-right: 26px;
  text-transform: none;
  text-decoration: none;
  color: #2290E0;
  border-color: #2290E0;
}
button.MuiButton-outlinedSecondary {
  border-radius: 2px;
  height: 36px;
  font-size: 14px;
  padding-left: 26px;
  padding-right: 26px;
  text-transform: none;
  text-decoration: none;
  color: #FF827B;
  border-color: #FF827B;
}
button.MuiButton-contained.Mui-disabled {
  border-radius: 2px;
  height: 36px;
  font-size: 14px;
  padding-left: 26px;
  padding-right: 26px;
  text-transform: none;
  text-decoration: none;
  background: #DDDDDD;
  color: #333333;
}
.defaultColor {
  color: #2290E0;
}
.decorationNone {
  text-decoration: none;
}
nav.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 16px;
  .homeIcon svg {
    float: left;
  }
}
button.modalClose {
  position: absolute;
  right: 15px;
  top: 15px;
  .MuiSvgIcon-root {
      font-size: 28px;
      color: #333333;
  }
}
.iconClear {
  .MuiButtonBase-root {
    margin-right: -12px;
    .MuiSvgIcon-root {
      font-size: 23px;
    }
  }
}

.modalButtonGroup {
  display: flex;
  justify-content: center;
  .MuiButtonBase-root {
    margin: 8px;
  }
}

body {
  .MuiFormLabel-root {
      font-weight: 600;
      font-size: 12px;
      transform: translate(0, -2px) scale(1);
  }
  .MuiInputBase-root {
    &.Mui-focused {
      .MuiIconButton-root {
        color: #2290E0;
      }
    }
  }
  .MuiInputBase-input {
      font-size: 14px;
  }
  .MuiFormControl-root {
    position: relative;
    .MuiFormHelperText-root.Mui-error {
      position: absolute;
      bottom: -20px;
    }
  }
  .MuiTableContainer-root {
    box-shadow: none;
  }
}
.boxForm .MuiFormControl-root {
  margin-bottom: 22px;
}

.dateControl {
  .MuiFormLabel-root {
    font-weight: 600;
  }
  .MuiIconButton-root {
    margin-left: -14px;
    margin-right: -8px;
    color: #2290E0;
  }
}
.MuiInputBase-root.Mui-focused {
  .MuiSelect-root {
    background-color: transparent;
  }
}
.search {
  width: 269px;
  .MuiInputBase-root {
    border: 0.5px solid #DADADA;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiIconButton-root {
    color: #DADADA;
    margin-top: 2px;
  }
  .MuiInputBase-root.Mui-focused {
    background-color: #fff;
  }
}
.title {
  font-size: 24px;
  font-weight: 600;
}
.checkbox {
  margin-top: -9px;
  margin-bottom: 16px;
  .MuiTypography-root {
    font-size: 14px;
  }
}
.disabledInput {
  border: none;
  background-color: transparent;
}
.breadcrumbs ol {
  li {
    a {
      color: #757575;
      font-size: 14px;
      &:hover {
        text-decoration: none;
      }
      &.last {
        text-decoration: none;
        color: #757575;
        cursor: inherit;
      }
    }
  }
}
.mobileMenu, .mobile-conference, .MuiSpeedDial-root.addMobile {
    display: none;
}



/*==========  Desktop First  ==========*/


@media only screen and (max-width: 1200px) {
    .search {
        width: 248px;
    }
}

@media only screen and (max-width: 992px) {
    .laptop img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    .main .title {
        font-size: 32px;
    }
}


@media only screen and (max-width: 768px) {
}


@media only screen and (max-width: 576px) {
}

@media only screen and (max-width: 480px) {
    body {
        padding-top: 0;
        background-color: #fff;
        .menuDrawer {
            display: none;
        }
        .mainContainer {
            margin-left: 0;
        }
        .main {
            padding: 20px;
        }
        .main .title {
            font-size: 24px;
            margin-bottom: 20px;
        }
        header.MuiPaper-root {
            display: none;
        }
        .whiteBg {
            padding: 15px 20px;
        }
        .grayBg {
            padding: 15px 20px;
        }
        .conferenceBox {
            min-width: 100%;
            max-width: 100%;
        }
        .conferenceTab {
            display: flex;
        }
        .conferenceTab button {
            width: 100%;
        }
        ul.mobileMenu {
            position: fixed;
            justify-content: space-around;
            bottom: 0;
            z-index: 99;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            box-sizing: border-box;
            display: flex;
            background-color: #fff;
            padding: 0;
            margin-block-start: 0;
            margin-block-end: 0;
        }
        .mobileMenu a {
            text-decoration: none;
            color: #898989;
            width: 50%;
        }
        .mobileMenu a.active {
            color: #2290E0;
        }
        .mobileMenu a .MuiTypography-root {
            font-size: 12px;
            font-weight: 600;
        }
        .mobileMenu .MuiListItemText-root {
            margin: 0;
            margin-top: -6px;
        }
        .mobileMenu .MuiListItem-button {
            display: block;
            text-align: center;
            margin: auto;
        }
        .mobileMenu .MuiListItemIcon-root {
            min-width: auto;
        }
        .mobileMenu .MuiListItemIcon-root path {
            fill: #898989;
        }
        .mobileMenu a.active .MuiListItemIcon-root path {
            fill: #2290E0;
        }
        .mobileMenu .MuiBottomNavigationAction-label {
            font-weight: 600;
            font-size: 12px;
        }
        .desktopBtn {
            display: none;
        }
        .newСonference {
            padding-top: 10px;
            height: 100vh;
            box-sizing: border-box;
            position: relative;
        }
        .newСonference .maxWidth {
            max-width: 100%;
        }
        .newСonference .MuiFormControl-root {
            width: 100%;
        }
        .desktop-conference {
            display: none;
        }
        .mobile-conference {
            padding: 20px;
        }
        .MuiFormLabel-root {
            font-size: 17px;
            color: #333333;
            transform: translate(0, -6px) scale(1);
        }
        .boxTime__item {
            padding-top: 6px;
        }
        .boxTime__title {
            font-size: 17px;
            color: #333333;
        }
        .mobile-conference .MuiGrid-item {
            padding: 12px;
        }
        .mobile-conference .MuiFormControl-root {
            display: flex;
        }
        .boxTime__item .boxForm:first-child {
            width: 120px;
        }
        .boxTime__item .boxForm:last-child {
            width: 100%;
        }
        .mobileAddList {
            margin-top: 8px;
            padding-bottom: 4px;
        }
        .mobileAddList .MuiButtonBase-root {
            padding: 0;
        }
        .mobileAddList .MuiTypography-root {
            color: #2290E0;
            font-size: 16px;
            font-weight: 700;
        }
        .mobileAddList .MuiListItemIcon-root {
            min-width: auto;
            color: #2290E0;
            font-size: 14px;
        }
        .mobileAddList .MuiListItemIcon-root svg {
            font-size: 22px;
        }
        .back {
            display: flex;
            align-items: center;
            color: #2290E0;
            margin-bottom: 20px;
            margin-left: -5px;
            font-size: 17px;
            text-decoration: none;
        }
        .mobile-conference button.mobileBtn, .drawerFullWidth button.mobileBtn {
            height: 50px;
            max-width: 288px;
            width: 100%;
            box-sizing: border-box;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 20px;
            margin: auto;
            font-size: 17px;
            font-weight: 600;
            z-index: 9;
        }
        .modileConferenceList {
            display: flex;
            align-items: center;
            text-align: center;
        }
        .modileConferenceList .empty {
            font-size: 17px;
            color: #757575;
            text-align: center;
            margin: auto;
            height: 22vh;
            display: flex;
            align-items: center;
        }
        .drawerFullWidth .MuiDrawer-paper {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
        }
        .drawerFullWidth .title {
            font-size: 17px;
            margin-bottom: 15px;
        }
        .drawerFullWidth .search {
            width: 100%;
        }
        .drawerFullWidth .search .MuiInputBase-input {
            font-size: 16px;
        }
        .usersList {
            margin-bottom: 50px;
        }
        .usersList .MuiListItem-gutters {
            padding-left: 0;
            padding-right: 0;
            padding-top: 6px;
            padding-bottom: 6px;
            align-items: center;
        }
        .usersList .MuiTypography-root {
            font-weight: 600;
            font-size: 14px;
        }
        .usersList .position {
            font-size: 14px;
            color: #333333;
            font-weight: 400;
    
        }
        .usersList .MuiListItemAvatar-alignItemsFlexStart {
            margin-top: 0;
        }
        .usersList .MuiSvgIcon-root {
            color: #007AFF;
        }
        .modileConferenceList .MuiSvgIcon-root {
            color: red
        }
        .modileConferenceList ul {
            width: 100%;
        }
        .mobileAddListCount {
            color: #757575;
            font-size: 14px;
            margin-bottom: 10px;
            display: block;
        }
        .deleteText {
            font-size: 17px;
            color: #333333;
            text-transform: none;
        }
        .startNowModal .startNowModal__content {
            padding: 20px;
        }
        .startNowModal .title {
            font-size: 20px;
        }
        .MuiDialog-paper {
            margin: 15px;
        }
        .conferenceBox__title {
            min-height: auto;
        }
        .countDown {
            padding: 20px;
            box-sizing: border-box;
        }
        .countDown .title {
            margin-bottom: 30px;
            font-size: 16px;
        }
        .countDown .countdown .number {
            font-size: 34px;
            color: #000000;
        }
        .countDown .countTimer {
            min-height: 98px;
            padding: 10px 32px;
        }
        .countDown .modalButtonGroup {
            position: fixed;
            bottom: 20px;
            left: 0;
            right: 0;
            margin: auto;
            display: block;
        }
        .countDown .currentGoes {
            font-size: 16px;
        }
        .countDown button.MuiButton-outlinedPrimary, .countDown button.MuiButton-containedPrimary {
            max-width: 288px;
            width: 100%;
            height: 50px;
            font-size: 17px;
            font-weight: 600;
        }
        .authorization {
            background: #fff;
        }
        .authorization__logo {
            text-align: center;
            margin: auto;
        }
        .authorization__logo svg {
            width: 250px;
            height: 90px;
        }
        .authorization__text {
            font-size: 14px;
            text-align: center;
        }
        .authorization .MuiButtonBase-root {
            height: 50px;
            max-width: 288px;
            width: 100%;
            font-size: 17px;
            font-weight: 600;
        }
        .authorization button.MuiButton-contained.Mui-disabled {
            height: 50px;
            max-width: 288px;
            width: 100%;
            font-size: 17px;
            font-weight: 600;
        }
        .MuiSpeedDial-root.addMobile {
            display: flex;
        }
        .mobile-conference {
            display: block;
        }
        .conferences {
            padding-bottom: 56px;
        }
        .conferenceBox {
            min-height: 130px;
        }
        .conferences .MuiGrid-spacing-xs-3 > .MuiGrid-item {
            padding: 6px 12px
        }
        .loginModal .loginModal__content {
            padding: 20px;
        }
        .loginModal .loginModal__content .title {
            font-size: 20px;
        }
        .loginModal .loginModal__content .MuiFormLabel-root {
            font-size: 13px;
        }
        .mobilePassword {
            display: flex;
        }
        .mobilePassword .MuiTypography-root {
            font-size: 14px;
        }
        .mobilePublic {
            display: flex;
            align-items: center;
            min-height: 34px;
            margin-left: -2px;
        }
        .mobilePublic .MuiButtonBase-root {
            padding: 0;
            padding-right: 9px;
        }
        .mobilePublic .publicLink {
            display: flex;
            align-items: center;
        }
        .mainBottom {
            padding: 20px;
        }
        .main .convenience__title {
            font-size: 18px;
        }
        .main .convenience p {
            font-size: 16px;
        }
        .mainBottom__icon {
            margin-right: 24px;
        }
        .mainBottom__item {
            padding-top: 8px;
            padding-bottom: 8px;
            max-width: 100%;
        }
        .MuiInputBase-input {
            font-size: 16px;
        }
        .startConferenceName {
            margin-bottom: 15px;
            .MuiFormLabel-root {
                text-align: center;
                margin: auto;
                right: 0;
            }
            .MuiInputBase-input {
                text-align: center;
            }
        }
        .main .laptop {
            display: block;
            margin-bottom: 20px;
        }
        .authorization__content {
            padding: 16px 34px 24px;
        }
        .authorization__form {
            max-width: 100%;
        }
        .conferenceBox__edit {
            z-index: 9;
            right: 10px;
            top: 10px;
            opacity: 1;
        }
        .conferenceBox:hover .conferenceBox__title {
            background: none;
            border-color: transparent;
        }
        .edit-mobile {
            button.mobileBtn {
                position: static;
                max-width: 100%;
                margin-bottom: 50px;
            }
            .usersList {
                margin-bottom: 10px;
            }
        }
    }
}

