header {
    .MuiButtonBase-root {
        text-transform: inherit;
        font-size: 14px;
        position: relative;
        margin-left: 30px;
        &::before {
            content: '';
            position: absolute;
            height: 22px;
            width: 1px;
            background-color: #fff;
            left: -9px;
        }
    }
    .white {
        color: #fff;
    }
}

div.header {
    &__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 56px;
    }
    &__profile-box {
        display: flex;
        .MuiButtonBase-root {
            margin-top: 3px;
        }
    }
    &__profile {
        display: flex;
        align-items: center;
        .MuiAvatar-root {
            margin-right: 15px;
        }
        .MuiTypography-root {
            font-size: 14px;
        }
    }
}